.headertitle {
    font-size: 18px;
    display: flex;
    align-content: center;
    margin: 1.3em 1.3em;
}

.formContainer
{
    height: 50%;
    box-shadow: 0 4px 2px -2px gray;
    padding-top: 10px;
}

#table{
    margin-top: -85px;
}