.navbar-container {
  background-color: #111111;
  overflow:hidden;
  z-index: 9999;
}
.navbar-icon {
  height: 15vh;
  width: 100%;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-bottom-left-radius: 20px;
  align-self: flex-end;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  transition: ease-in-out 0.5s;
}
.navigation-container {
  display: flex;
  padding: 20px !important;
  height: 80.5vh;
  flex-direction: column;
}

.mybutton {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  align-self: flex-end;
  margin-bottom: 0.5rem;
  transition: ease-in-out 0.5s;
  text-transform: uppercase;
}

.mybutton:hover {
  background-color: #535353;
  color: #000000;
  transition: ease-in-out 0.2s;
}
.mybutton-active:hover {
  background-color: #535353;
  transition: ease-in-out 0.2s;
}

.mybutton-active {
  background-color: #aaaaaa;
  color: #000000;
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  align-self: flex-end;
  margin-bottom: 0.5rem;
  transition: ease-in-out 0.2s;
  text-transform: uppercase;
}
.custom-hr {
  border: none;
  height: 1px;
  border-top: 1px solid #ffffff; /* Set the color of the hr */
}

.navigation-container {
  margin-top: 1.5em;
}