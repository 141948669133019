.loginImage {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.loginImage.visible {
  opacity: 1;
}

.loginForm {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.loginForm.visible {
  opacity: 1;
}

.copyright {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: center;
}

.copyright.visible {
  opacity: 1;
}