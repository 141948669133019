/* .formImputs{
    width: 250px !important;
} */

.form{
    padding-bottom: 10px;
}

.submitbtn{
    max-width: 200px;
}
.flexColunm{
    gap: 5px;
}

.flexformrow
{
  margin-top:10px !important;
}
.formrowBig
{
    /* margin-bottom: -25px; */
    height: 120px;
    flex: 1;
    
}

.dialog
{
    max-height: 70%;
    max-width: 70%;
    overflow: hidden;
}

.dialog img {
    height: 100%;
    width: 100%;
 }

 .spinner
{
    position: fixed;
    position: absolute;
    left: 50%;
    top: 35%;
    z-index: 1000;
    height: 60px;
    width: 31px;
}