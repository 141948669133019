.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-uploader-label {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .image-uploader-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .image-uploader-preview img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 0.5rem;
    transition: all 0.3s ease-in-out;
  }
  
  .image-uploader-preview img:hover {
    transform: scale(1.1);
  }
  