
.profile--false{
    background-color: #cecbc7;
}
.profile--false:hover{
    background-color: blue;
}

.searchInput{
    height: 35px  !important;
    width: 300px !important;
    font-size: medium;
}