/* .formImputs{
    width: 250px !important;
} */

.form{
    padding: 0em .5em;
}

.submitbtn{
    max-width: 200px;
}

.formrow
{
    margin-bottom: -11px;
}

.dialog
{
    max-height: 70%;
    max-width: 70%;
    overflow: hidden;
}

.dialog img {
    height: 100%;
    width: 100%;
 }

 .spinner
{
    position: fixed;
    position: absolute;
    left: 50%;
    top: 35%;
    z-index: 1000;
    height: 60px;
    width: 31px;
}

.lastrow{
    margin-top: 15px;
    margin-bottom: 30px;
}
/*
.btnsubmit {
    position: static;
    float: right;
}*/