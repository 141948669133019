.header{
    font-size: 25px;
    display: flex;
    align-content: center;
}

.formContainer
{
    height: 50%;
    box-shadow: 0 4px 2px -2px gray;
    padding-top: 10px;
}

#table{
    margin-top: -85px;
}