.root {
  display: flex;
  flex-direction: row;
  background-color: #5854ff;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.bodycontent{
  overflow : scroll;
}

.sidebar
{
  
  overflow:hidden;
}
